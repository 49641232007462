import React from "react"
import Layout from "../../Layout"
import Seo from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import { AiOutlineExclamationCircle } from "react-icons/ai"
import { Link } from "gatsby"

const CartCancelled = () => {
  return (
    <Layout>
      <Seo title="Order Cancelled" noIndex />

      <Breadcrumbs title={"Order Cancelled"} />

      <div className="container my-4 py-4">
        <div className="row">
          <div className="col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-center">
            <span className={"text-warning"}>
              <AiOutlineExclamationCircle size={60} />
            </span>
            <h4 className={"mt-4"}>Do you really want to cancel?</h4>
            <p>
              It seems you've decided to cancel the payment.
              <br />
              We still have your order if you change your mind to pay on
              delivery.
            </p>
            <Link className={"btn btn-primary"} to={"/cart"}>
              Go to cart
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CartCancelled
